import * as React from 'react';
import { Visibility, Transition } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

interface State {
    value1Visible: boolean;
    value2Visible: boolean;
    value3Visible: boolean;
}

class ModernTechSection extends React.Component<any, State> {
    state = {
        value1Visible: false,
        value2Visible: false,
        value3Visible: false,
    }
    handleUpdate = (e: any, data: any, valueName: string) => {
        switch (valueName) {
            case "value1Visible":
                if (!this.state.value1Visible && data.calculations.topVisible) {
                    this.setState({ value1Visible: true })
                    return "";
                }
                return "";
            case "value2Visible":
                if (!this.state.value2Visible && data.calculations.topVisible) {
                    this.setState({ value2Visible: true })
                    return "";
                }
                return "";
            case "value3Visible":
                if (!this.state.value3Visible && data.calculations.topVisible) {
                    this.setState({ value3Visible: true })
                    return "";
                }
                return "";
            default:
                return {}
        }
    }
    scrollToTop = () => {
        window.scroll(0, 0);
    }
    render() {
        const { value1Visible, value2Visible, value3Visible } = this.state;

        return (
            <div className="modern-values-container" id="core-values">
                <div className="modern-values-content">
                    <div className="modern-value-row">
                        <div className="values-header-container" >
                            <div className="lighter-header">Modern</div>
                            <div className="brighter-header">Technology</div>
                        </div>
                    </div>
                    <div className="modern-value-rows User">
                        <Visibility fireOnMount onUpdate={(e, data, valueName = "value1Visible") => this.handleUpdate(e, data, valueName)} className="visibility-value-container">
                            <React.Fragment>
                                <Transition visible={value1Visible} animation="fade up" duration={2500} unmountOnHide={true}>
                                    <div>
                                        <div className="modernTech-ImageText">
                                            <div className="modernTech-Image">
                                                <div className="iconExperience"></div>
                                            </div>
                                            <div className="modernTech-Text">
                                                <div className="modernTechHeader">User Experience</div>
                                                <p className="value-paragraph"> First Integrity Title intersects the traditional process with a focus of technology, cost and process efficiency to a simplistic experience.</p>
                                            </div>
                                        </div>
                                    </div>
                                </Transition>
                            </React.Fragment>
                        </Visibility>
                    </div>
                    <div className="modern-value-rows Automated">
                        <Visibility fireOnMount onUpdate={(e, data, valueName = "value2Visible") => this.handleUpdate(e, data, valueName)} className="visibility-value-container">
                            <React.Fragment>
                                <Transition visible={value2Visible} animation="fade up" duration={2500} unmountOnHide={true}>
                                    <div>
                                        <div className="modernTech-ImageText">
                                            <div className="modernTech-Image">
                                                <div className="iconExperience Automated">

                                                </div>
                                            </div>
                                            <div className="modernTech-Text">
                                                <div className="modernTechHeader">Automated Underwriting Decisions</div>
                                                <p className="value-paragraph">Automated commitment and policy underwriting with the most sophisticated underwriting platform ever implemented in the industry.</p>
                                            </div>
                                        </div>
                                    </div>
                                </Transition>
                            </React.Fragment>
                        </Visibility>
                    </div>
                    <div className="modern-value-rows Automated">
                        <Visibility fireOnMount onUpdate={(e, data, valueName = "value3Visible") => this.handleUpdate(e, data, valueName)} className="visibility-value-container">
                            <React.Fragment>
                                <Transition visible={value3Visible} animation="fade up" duration={2500} unmountOnHide={true}>
                                    <div>
                                        <div className="modernTech-ImageText">
                                            <div className="modernTech-Image">
                                                <div className="iconExperience Seamless">

                                                </div>
                                            </div>
                                            <div className="modernTech-Text">
                                                <div className="modernTechHeader">Seamless Closing Experience</div>
                                                <p className="value-paragraph">Real-time status and documents for all your transactions – all in one place.</p>                                               

                                            </div>
                                        </div>
                                    </div>
                                </Transition>
                            </React.Fragment>
                        </Visibility>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModernTechSection;