import * as React from 'react';
import { NavLink } from 'react-router-dom';

class CustomersWeServeSection extends React.Component<any, any> {
    scrollToTop = () => {
        window.scroll(0, 0);
    }
    render() {
        return (
            <div>
                <div className="customers-serve-ImgContainerMob">
                    <div className="customers-customers-Image">
                        <div className="customer-container-column-right">
                        </div>
                    </div>
                </div>
                <div className="customers-types-container">
                    <div className="customers-content-container">
                        <div className="customers-contentMain-container">

                            <div className="customers-text-background-container">

                                <div className="customers-text-content">
                                    <div className="customers-header-container" >
                                        <div className="customers-brighter-header">Customers <span className="customers-lighter-header">we serve</span></div>
                                        <div className="customers-serve-sec">
                                            <div className="customers-serve-sec-left">
                                                <div className="customers-serve-section">
                                                    Lenders
                                                    <p className="customers-serve-des">First Integrity Title offers financial institutions with customizable title and escrow solutions using quality standards.  Using our technology, operational efficiencies are developed by combining today’s market conditions with our client’s expectations.</p>
                                                </div>
                                                <div className="customers-serve-section services">
                                                    Servicers
                                                    <p className="customers-serve-des">First Integrity Title offers Servicers a multitude of customized product and service solutions based on volumes and requirements.</p>
                                                </div>                                            
                                            </div>
                                            <div className="customers-serve-sec-right">
                                                <div className="customers-serve-section services">
                                                    Attorney/Trustees
                                                    <p className="customers-serve-des">First Integrity Title provides multiple foreclosure products to enable the Attorney or Trustee to process non-judicial and judicial foreclosures on residential, commercial and mobile home properties.</p>
                                                </div>
                                            </div>
                                        </div>                                     
                                    </div>
                                </div>
                            </div>
                            <div className="customers-serve-ImgContainerWeb">
                                <div className="customers-customers-Image">
                                    <div className="customer-container-column-right">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CustomersWeServeSection;