import * as React from 'react';
import { Link } from 'react-scroll';
import NavBar from './navBar';



class HeroSection extends React.Component<any, any> {
    render() {
        return (
            <div>
                <div className="navbar-hero-container">
                    <div className="homepage-hero-container">
                 
                        <div className="hero-content">                          
                            <div className="hero-text-content-containerWeb">
                                <div className="header-4 hero-title" >Delivering the closing</div>
                                <div className="header-4 hero-title" >experience of</div>
                                <div className="header-4 hero-title" >tomorrow, today.</div>
                                     
                                <div className="hero-btn-sec">
                                    <Link to="mission-statement-containerHero" offset={-80} smooth={true} duration={750} className="hero-btn-pink-button-LearnMore">
                                        <div style={{ width: '70%' }}>
                                            Learn More
                                        <span className="customers-icon_right"></span>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hero-text-content-containerMob">
                    <div className="hero-title-difference" >Delivering the real estate closing experience</div>
                    <div className="hero-paragraph">
                        of tomorrow, today.
                    </div>
                    <div className="hero-btn-sec">
                        <Link to="mission-statement-containerHero" offset={-80} smooth={true} duration={750} className="hero-btn-pink-button-LearnMore">
                            <div style={{ width: '70%' }}>
                                Learn More
                                <span className="customers-icon_right"></span>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default HeroSection;