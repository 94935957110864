import * as React from 'react';
import { Transition } from 'semantic-ui-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface OwnProps {
	appLoaded: boolean;
}

type Props = OwnProps & RouteComponentProps;

interface State {
	logoVisible: boolean;
    callUsVisible: boolean;
    isPrivacyTermsPage: boolean;
}

class NavBar extends React.Component<Props, State> {

	state: State = {
		logoVisible: false,
        callUsVisible: false,
        isPrivacyTermsPage: false
	}

    componentDidMount() {

        const { location } = this.props;

        // header logo text gets different style if on privacy or terms page
        if (location.pathname === '/privacy' || location.pathname === '/terms') {
            this.setState({ isPrivacyTermsPage: true });
        } else {
            this.setState({ isPrivacyTermsPage: false });
        }
    }

    componentDidUpdate(prevProps: Props) {

        const { location, appLoaded } = this.props;

		if (appLoaded && prevProps.appLoaded === false) {
			setTimeout(() => {
				this.setState({ logoVisible: true });
			}, 1000);

			setTimeout(() => {
				this.setState({ callUsVisible: true });
			}, 1500);
        }

        if (prevProps.location.pathname !== location.pathname) {
            if (location.pathname === '/privacy' || location.pathname === '/terms') {
                this.setState({ isPrivacyTermsPage: true });
            } else {
                this.setState({ isPrivacyTermsPage: false });
            }
        }

    }

	render() {

        const { logoVisible, isPrivacyTermsPage } = this.state;

        return (
			<div className="navbar-container">				
				<div className="navbar-content">
					<Transition visible={logoVisible} mountOnShow={true} animation="fade down" duration={1500}>
						<div className="title-container">
							<h2 className={`header-2 ${isPrivacyTermsPage ? 'company-title' : ''} `}>FIRST INTEGRITY</h2>
                            <h3 className={`header-3 ${isPrivacyTermsPage ? 'company-title' : ''} `}>TITLE COMPANY</h3>
                            <h5 className={`header-light title-sub ${isPrivacyTermsPage ? 'company-title' : ''} `}>A CALIFORNIA CORPORATION</h5>
						</div>
					</Transition>
                    {/*<Transition visible={callUsVisible} mountOnShow={true} animation="fade down" duration={1500}>
						<a href="tel:+1-888-346-8485">
							<div className="call-us">Contact Us: (949) 392-8471</div>
						</a>	
					</Transition>					*/}
				</div>								
			</div>
		);
	}
}

export default withRouter(NavBar);
