
import * as React from 'react';
import { Link } from 'react-scroll';
import { NavLink } from 'react-router-dom';



class Footer extends React.Component<any, any> {
    scrollToTop = () => {
        window.scrollTo(0, 0);
    }
    getCurrentYear = () => {
        let dt = new Date();
        let year = dt.getFullYear();
        return year;
    }

    render() {
        return (
            <div className="footer-container">
                <div className="footer-content-container">
                    <div className="footer-contentMain-container">
                        <div className="footer-text-background-container">
                            <div className="footer-text-content">
                                <div className="footer-text-contentLeft">
                                    <h2 className="header-2">FIRST INTEGRITY</h2>
                                    <h3 className='header-3'>TITLE COMPANY</h3>
                                    <h5 className='header-light'>A CALIFORNIA CORPORATION</h5>
                                    <div className="footer-allRightsWeb">© {this.getCurrentYear()} First Integrity Title Company.  &nbsp; A California Corporation.  &nbsp; All Rights Reserved.</div>
                                    <div className="footer_rights">
                                        <NavLink to="privacy" className="footer-textPolicy Privacy">Privacy Policy</NavLink>
                                        <NavLink to="terms" className="footer-textPolicy">Terms of Service</NavLink>
                                        <a href="https://titlefy-resources.s3-us-west-2.amazonaws.com/FIN/optout+(1).pdf" target="_blank" className="footer-textPolicy">Do Not Sell My Personal Information</a>
                                    </div>
                                </div>
                                <div className="footer-page-section">
                                    <div className="footer-section">
                                        <div className="footer-section-right">
                                            <div className="footer-pageNav">18201 Von Karman, Suite 330</div>
                                            <div className="footer-pageNav">Irvine, CA 92612</div>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <a href="tel:1-888-346-8485" className="footer-pageNav">(949) 392-8471</a>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <a href="mailto:info@fintitle.com" className="footer-pageNav">info@fintitle.com</a>
                                            </div>
                                        </div>
                                    </div>
                                    <Link to="navbar-hero-container" offset={0} smooth={true} duration={1000} className="footer-bottomToTop">
                                        <div className="footer-bottomToTopIcon"></div>
                                    </Link>
                                    <div className="footer-allRightsMob">© {this.getCurrentYear()} First Integrity Title Company.  &nbsp; A California Corporation.  &nbsp; All Rights Reserved.</div>
                                    <div className="footer_rightsMob">
                                        <NavLink to="privacy" className="footer-textPolicy Privacy">Privacy Policy</NavLink>
                                        <NavLink to="terms" className="footer-textPolicy">Terms of Service</NavLink>
                                    </div>
                                    <a href="https://titlefy-resources.s3-us-west-2.amazonaws.com/FIN/optout+(1).pdf" target="_blank" className="footer-textPolicyMob">Do Not Sell My Personal Information</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;