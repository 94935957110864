import React, { Component } from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import './App.scss';
import NavBar from './components/navBar';
import Footer from './components/footer';
import 'semantic-ui-css/semantic.min.css'
import HomePageContainer from './components/homePageContainer';
import PrivacyPageContainer from './components/privacyPage';
import TermsPageContainer from './components/termsConditionPage';
import { CookieBanner } from './components/cookieBanner';
interface State {
	appLoaded: boolean;
}

class App extends Component<any, State> {

	state = {
		appLoaded: false
	}

	componentDidMount() {
		window.addEventListener("load", () => {
            this.setState({ appLoaded: true })
		});
	}

	render() {

        const { appLoaded } = this.state;

        return (							
            <div>
                <Router>
                    <>
                        <NavBar appLoaded={appLoaded} />
                        <Switch>                    
						    <Route path='/privacy' component={PrivacyPageContainer} />                    
						    <Route path='/terms' component={TermsPageContainer} />
                            <Route path='/' component={HomePageContainer} />    
                        </Switch>
                        <CookieBanner />
                    </>
                    
                </Router>
            </div>
		);
	}
}

export default App;
