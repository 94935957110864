import * as React from 'react';

class MissionStatementSection extends React.Component<any, any> {
    render() {
        return (
            <div className="mission-statement-container" id="mission-statement-containerHero">
                <div className="mission-plusIconUp"></div>
                <div className="mission-statement">
                    <div className="mission-mainStatement">
                        <div className="mission-Fin">
                            First Integrity Title Company is
                        </div>
                            <div className="mission-desc">
                            a tech-forward <span className="mission-desc-title">title insurance</span> and <span className="mission-desc-title">real estate closing</span> services company focused on a logical blend of technology and high-touch service that delivers truly enjoyable customer experience.

                            </div>
                    </div>
                </div>
                <div className="mission-plusIconDown"></div>
            </div>
        );
    }
}

export default MissionStatementSection; 