import * as React from 'react';
import { Visibility, Transition } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

interface State {
    elementVisible: boolean;
}
class HighTouchServiceSection extends React.Component<any, State> {
    state = {
        elementVisible: false
    }

    handleVisibilityUpdate = (e: any, data: any) => {
        const { elementVisible } = this.state;
        const { topVisible, bottomVisible } = data.calculations;

        if (!elementVisible && (topVisible || bottomVisible)) {
            this.setState({
                elementVisible: true,
            })
        }
    }
    scrollToTop = () => {
        window.scroll(0, 0);
    }
    render() {
        const { elementVisible } = this.state;
        return (
            <Visibility fireOnMount onUpdate={this.handleVisibilityUpdate} className="high-serviceContainer">
                <Transition visible={elementVisible} animation="fade left" duration={2500}>
                    <div className="highTouch-transition-containerMob">
                        <div className="highTouch-container-column-right">
                            <div className="tech-img-container" />
                        </div>
                    </div>
                </Transition>
                <div className="homepage-technology-container">
                    <div className="tech-content-container">
                        <div className="tech-text-background-container">
                            <div className="tech-text-content">
                                <div className="high-touchText">High-Touch Service</div>
                                <div className="tech-text-paragraphs">
                                    Every transaction requires a level of service and First Integrity Title offers customized services to suit the needs of our clients for any product or service requested.  From fully automated processes to human-assisted interaction, First Integrity Title provides the right level of high-touch service each client requires.
									<div className="high-touch-LearnMore">                                     
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Transition visible={elementVisible} animation="fade left" duration={2500}>
                            <div className="highTouch-transition-containerWeb">
                                <div className="highTouch-container-column-right">
                                    <div className="tech-img-container" />
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>
            </Visibility>
        );
    }
}

export default HighTouchServiceSection;