import * as React from 'react';
import HeroSection from './heroSection';
import MissionStatementSection from './missionStatementSection';
import HighTouchServiceSection from './highTouchServiceSection';
import ModernTechSection from './modernTechSection';
import CustomerExperienceSection from './customerExperienceSection';
import ServicesTypeSections from './servicesTypesSection';
import CustomerWeServeSection from './customersWeServeSection';
import SendNoteForm from './sendNoteForm';
import Footer from './footer';
import { NavLink } from 'react-router-dom';


interface Props {
    hiringLinkClicked: boolean;
    resetHiringLink: () => void;
}



class HomePageContainer extends React.Component<Props, any> {

    scrollToTop = () => {
        window.scroll(0, 0);
    }

    render() {

        const { hiringLinkClicked, resetHiringLink } = this.props;

            return (
                <div className="homepage-container">
                    <HeroSection />
                    <MissionStatementSection />
                    <HighTouchServiceSection />
                    <ModernTechSection />
                    <CustomerExperienceSection />
                    <ServicesTypeSections />
                    <CustomerWeServeSection />  
                    <SendNoteForm hiringLinkClicked={hiringLinkClicked} resetHiringLink={resetHiringLink} />
                    <Footer />
                </div>
        );
    }
}

export default HomePageContainer;